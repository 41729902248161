import { Cmp, Ctrl, log, state } from ":mods";
import { getProfileDetails } from "../apis";
import { _token_actions, actions, events } from "../store";
import { Show, createEffect } from "solid-js";
import { $token } from "../store";
import { ROUTES } from "../const";
import { logout } from "../apis";

export function TopProfile(props: Ctrl.Nav.model.TopNavJSXItemProps) {
  const $data = state.create({
    loading: true,
    image: "/images/profile_image_template.png",
    name: "jane doe",
  });
  createEffect(() => {
    const value = $token.value;
    if (value?.details) {
      const details = value?.details;
      getProfileDetails(String(details.user_id)).then(({ data }) => {
        $data.set((s) => {
          const first = data.first_name ?? "";
          const last = data.last_name ?? "";
          let name = undefined;
          if (first.length <= 0 && last.length <= 0) {
            name = s.name;
          } else {
            name = first + " " + last;
          }
          return {
            loading: false,
            name: name,
            image: data.profile_image ?? s.image,
          };
        });
      });
    }
  });
  return (
    <Ctrl.Nav.TopElement
      base={props.base}
      arrowMode="end"
      groupClass="flex flex-row items-center space-x-2"
      itemsClass="flex flex-col bg#p px-2 py-1 space-y-3"
      iconClass="icon-local-top-nav:down-arrow"
      iconRotationClass={{
        idle: "rotate-90",
        rotated: "",
      }}
      items={[
        {
          title: "Profile",
          icon: "icon-iconamoon:profile-light",
          to: ROUTES.profile.settings,
        },
        {
          title: "Logout",
          icon: "icon-material-symbols:logout",
          onClick: () => {
            // logout
            Cmp.Popup.actions.pushConfirmDialog({
              title: "Logout",
              message: "Are you sure you want to logout?",
              events: {
                onConfirmed: () => {
                  _token_actions.rm();
                  props.actions.navigateHref({ base: Ctrl.Routes.CONST.BASE_ROUTES.auth, path: ROUTES.auth.login });
                  logout()
                    .then(() => {
                      props.actions.pushToast({ type: "info", message: `Sad to see you go ${$data.value.name}` });
                    })
                    .catch((e) => {
                      props.actions.pushToast({ type: "error", message: `an error occured while trying to logout!.` });
                      log.all.error("an error occured while trying to logout!. :: ", e);
                    });
                },
              },
            });
          },
        },
      ]}
    >
      <section class="relative flex flex-row items-center !w-8rem truncate">
        {/* TODO: add loader skeletons */}
        <div class="flex flex-row items-center justify-center whitespace-nowrap space-x-4 cursor-pointer !z-50">
          <img src={$data.value.image} class="w-45px h-45px rounded-full" />
          <span class="capitalize">{$data.value.name}</span>
        </div>
      </section>
    </Ctrl.Nav.TopElement>
  );
}
